/**
 *
 * Signin
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import H2 from 'components/H2';
import SVG from 'react-inlinesvg';
import FrenchLogo from 'images/PC-PeopleCare-Logos_FR.svg';
import pcEapLogo from 'images/pc-eap-eng-logo.png';
import pcEapLogoFr from 'images/pc-eap-fr-logo.png';
import Logo from 'images/PC-Nav.svg';
import BBDLogo from 'images/BBDLogo.png';
import BBDLogoFr from 'images/BBDLogoFr.png';
import SignInImg from 'images/PC-Sign.svg';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { tabletSize, phoneSize } from 'global-styles';
import { BBDBAP, BBDEAP } from 'utils/environment';
import pcCarepathBapLogoFr from 'images/pcCarePathBapFr.png';
import pcCarepathBapLogo from 'images/pcCarePathBap.png';
import bbdSignUpImage from 'images/pc-bbd-sign.png';
import SignInForm from './SignInForm';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';

const SigninWrapper = styled.div`
  display: flex;
  max-width: 905px;
  min-height: 740px;
  background-color: #ffffff;
  z-index: 1;
  position: relative;
  margin: 70px auto;
  box-shadow: 0 0 50px 0 rgba(40, 71, 140, 0.2);

  @media (max-width: ${tabletSize}) {
    width: 90%;
  }
`;

const InfoContainer = styled.div`
  padding: 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  width: 75%;

  h2 {
    margin-bottom: 40px;
  }

  @media (max-width: ${tabletSize}) {
    padding-bottom: 200px;
    margin: 0 auto;
    width: 60%;

    h2 {
      text-align: center;
    }
  }

  @media (max-width: ${phoneSize}) {
    padding-bottom: 150px;
    width: 100%;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 58%;

  @media (max-width: ${tabletSize}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  margin: 40px;
  margin-right: 0;

  @media (max-width: ${tabletSize}) {
    margin-bottom: 100px;
  }

  @media (max-width: ${phoneSize}) {
    margin-bottom: 80px;
  }
`;

const BBDLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ImageWrapper = styled.div`
  width: 42%;
  background-color: #f6f8fa;

  img {
    width: 100%;
    margin-left: -30px;
    margin-top: 50%;
  }

  @media (max-width: ${tabletSize}) {
    display: none;
  }
`;

class Signin extends React.PureComponent {
  renderLogo = locale => {
    if (BBDBAP && locale === 'fr') {
      return (
        <BBDLogoWrapper>
          <img style={{ width: '250px' }} src={BBDLogoFr} alt="bbd logo" />
          <img
            style={{ width: '250px' }}
            src={pcCarepathBapLogoFr}
            alt="bbd carepath bap logo"
          />
        </BBDLogoWrapper>
      );
    }
    if (BBDBAP) {
      return (
        <BBDLogoWrapper>
          <img style={{ width: '250px' }} src={BBDLogo} alt="bbd logo" />
          <img
            style={{ width: '250px' }}
            src={pcCarepathBapLogo}
            alt="bbd carepath bap logo"
          />
        </BBDLogoWrapper>
      );
    }
    if (BBDEAP && locale === 'fr') {
      return (
        <BBDLogoWrapper>
          <img style={{ width: '250px' }} src={BBDLogoFr} alt="bbd logo" />
          <img style={{ width: '250px' }} src={pcEapLogoFr} alt="pc eap logo" />
        </BBDLogoWrapper>
      );
    }
    if (BBDEAP) {
      return (
        <BBDLogoWrapper>
          <img style={{ width: '250px' }} src={BBDLogo} alt="bbd logo" />
          <img style={{ width: '250px' }} src={pcEapLogo} alt="pc eap logo" />
        </BBDLogoWrapper>
      );
    }
    if (locale === 'fr') {
      return <SVG style={{ width: '250px' }} src={FrenchLogo} alt="logo" />;
    }
    return <SVG style={{ width: '250px' }} src={Logo} alt="logo" />;
  };

  renderPlanName = () => {
    if (BBDBAP) {
      return <FormattedMessage {...messages.bbdBap} />;
    }
    if (BBDEAP) {
      return <FormattedMessage {...messages.bbdEap} />;
    }
    return <FormattedMessage {...messages.peopleCare} />;
  };

  render() {
    const { locale } = this.props;
    return (
      <div>
        <Helmet>
          <title>Signin</title>
          <meta name="description" content="People Corp Signin" />
        </Helmet>
        <SigninWrapper id="signin">
          <LeftContainer>
            <Header>{this.renderLogo(locale)}</Header>
            <InfoContainer>
              <H2>
                <FormattedMessage
                  {...messages.welcome}
                  values={{
                    bold: (
                      <span style={{ fontWeight: '600' }}>
                        {this.renderPlanName()}
                      </span>
                    ),
                  }}
                />
              </H2>
              <SignInForm />
            </InfoContainer>
          </LeftContainer>
          <ImageWrapper>
            {BBDBAP || BBDEAP ? (
              <img src={bbdSignUpImage} alt="signup" />
            ) : (
              <img src={SignInImg} alt="signup" />
            )}
          </ImageWrapper>
        </SigninWrapper>
      </div>
    );
  }
}

Signin.propTypes = {};

const mapStateToProps = createStructuredSelector({
  locale: makeSelectLocale(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'signin', reducer });
const withSaga = injectSaga({ key: 'signin', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Signin);
